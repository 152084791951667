import React, { useEffect, useState  } from 'react';
import { Link } from "gatsby"

import Layout from "components/Layout";
import Seo from 'components/Seo';
import Map from "components/Map"

const Rider = ({ location }) => {
  const [marker, setMarker] = useState();

  const onMapMarker = async e => {
    setMarker(e)
    console.log('onMapMarker',e);
  }

  return (
    <Layout>
      <Seo
        title={'Digital Transit Solutions'}
        pathname={location.pathname}
      />
      {/*<section>
        <div  className="container">

        </div>
      </section>*/}

      <section>
        <div  className="container">
          {/*<h1>Digital Transit Solutions</h1>*/}
          <h1>مكان الصعود</h1>


          <br/>
          <Link to="1" role="button" className="secondary wide">المتابعة</Link>

        </div>
      </section>
    </Layout>
  );
}

export default Rider
